<template>

  <div id="overlay" />
  <Navigation
    v-if="component != 'Film' && component != 'Fotograf*in' && component != 'AtWork' && component != 'Projekt'"
    :component="component"
  />

  <main>
    <Dynamic v-if="content" :inject="content" />
    <Footer />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Footer from '@/components/structure/Footer.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';

export default {
  components: {

    Navigation,
    Footer,
    Dynamic,

  },

  setup() {
    dynamicPage();
  },

  data() {
    return {
      component: this.content,
    };
  },
  computed: {
    content() {
      console.log('teo2', this.$store.state.router.page?.content);
      return this.$store.state.router.page?.content;
    },
  },
  watch: {
    content() {
      this.component = this.content[0].component;
    },
  },
  mounted() {
    console.log('teo', this.component);
  },
};
</script>
