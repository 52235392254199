<template>

  <div id="nav" ref="nav" class="">
    <div class="logo">
      <!-- <Language /> -->
      <Go
        :to="'/' + $store.state.router.locale"
        aria-label="Home"
        class="logo-link link"
      >
        schau.
      </Go>
    </div>
    <div class="relative flex">

      <nav class="inner">
        <transition-group name="default">
          <Go
            v-for="link of navigation"
            :key="link.title"
            :to="link.uri"
            class="link self-start block"
            :class="[ pageLink === link.uri ? 'active' : '' ]"
            v-text="link.title"
          />
        </transition-group>

        <!-- filter gets teleported here -->
        <div id="filterBox" class="filter-box" />

      </nav>

      <Hamburger
        class="md:hidden"
        :class="{ active: mobile }"
        @click="toggle()"
      />
    </div>
  </div>

  <div class="mobile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition-group name="appear">
      <div v-if="mobile" class="mobile-nav">
        <Go
          v-for="link of navigation"
          :key="link.id"
          :to="link.uri"
          class="link px-4 pb-1"
          :class="[ pageLink === link.uri ? 'active' : '' ]"
          @click="toggle(true)"
          v-text="link.title"
        />

        <div class="px-4 py-4 mt-auto extra">
          <p>
            <Go to="/de/hallo" @click="toggle(true)">
              Kontakt
            </Go>
            <Go :to="footer.Impressum?.uri" @click="toggle(true)">
              {{ footer.Impressum?.title }}
            </Go>
          </p>
          <div class="flex" v-html="content?.kontaktlinks" />
          <p class="flex" v-html="content?.adresse" />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>

import Scrolllock from '@/hooks/scrolllock';
import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';

export default {
  components: {
    Language,
    Hamburger,
  },
  props: {
    component: String,
  },
  setup() {
    const { lock, unlock } = Scrolllock();
    return {
      lock,
      unlock,
    };
  },
  data: () => ({
    mobile: false,
    pageLink: '',
  }),
  computed: {
    content() {
      return this.$store.state.router.layout.content;
    },
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    page() {
      return this.$store.state.router.page;
    },
    footer() {
      return this.$store.state.router.layout.footer || {};
    },
  },
  watch: {
    page() {
      this.pageLink = this.$store.state.router.page?.content[0].data.link;
    },
  },
  methods: {
    toggle(close) {
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

#nav {
  position: fixed;
  top: 0;
  z-index: 100;
  padding-top: 4rem;
  padding-left: 50px;

  @media (max-width: theme("screens.md")) {
    width: 100vw;
    padding: 1rem 1rem 0 1rem;
    top: 0 !important;
    display: flex;

    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: white;
    }
  }
  @media (max-width: theme("screens.lg")) {
    padding-top: 2rem;
    padding-left: px(20);
  }

  .logo {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    @media (max-width: theme("screens.md")) {
      margin-bottom: 0;
    }
    .logo-link {
      color: var(--color-red);
    }
  }
}

.inner,
.mobile-nav,
:deep(.Language),
.hamburger {
  pointer-events: all;
}

.link {
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.1;
  color: var(--color-red);
  @media (max-width: theme("screens.lg")) {
    font-size: 1.75rem;
  }
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
  @screen md {
    display: none;
  }
}

.mobile-nav {
  position: absolute;
  overflow: scroll;

  display: flex;
  flex-direction: column;

  z-index: 99;
  padding-top: 8rem;
  left: 0;
  top: 0;
  height: calc(100vh - env(safe-area-inset-bottom));
  width: 100%;
  font-size: 2rem;

  a {
    display: block;
    text-decoration: none !important;
  }

  @screen md {
    display: none;
  }

}

:deep(.extra) {
  p {
    margin-bottom: .5em;
    font-size: 1rem;

  }
  a {
    display: inline-block;
    margin-right: .8rem;
    color: black;
  }
}

</style>
