<template>
  <div class="hamburger">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.hamburger {
  box-sizing: content-box;
  width: 2.2rem;
  padding: 15px;
  margin: -15px;
}

.bar {
  height: .23rem;
  background-color: var(--color-red);
  transition: all 0.5s;

  &:nth-child(1) {
    width: 100%;
    .active & {
      transform: translateY(.63rem) rotate(45deg);
    }
  }

  &:nth-child(2) {
    width: 100%;
    transform: translateX(0);
    margin-top: .4rem;
    margin-bottom: .4rem;
    .active & {
      opacity: 0;
      width: 0;
      transform: translateX(1.1rem);
    }
  }

  &:nth-child(3) {
    width: 100%;
    .active & {
      transform: translateY(-.63rem) rotate(-45deg);
    }
  }

}
</style>
