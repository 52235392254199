<template>
  <footer class="Footer bg-white justify-center">
    <div class="instagram-bar flex justify-center items-center">
      <Go :to="content?.schauaufinstagram" class="h1 text-black m-0">
        schau. auf Instagram
      </Go>
    </div>
    <div class="contact-bar row-24 justify-center m-0">
      <div class="col-22 m-0 flex flex-col md:flex-row items-center md:place-content-between">
        <div class="impressum-kontakt">
          <Go to="/de/hallo">
            Kontakt
          </Go>
          <Go :to="footer.Impressum?.uri">
            {{ footer.Impressum?.title }}
          </Go>
        </div>
        <div class="some-links flex flex-wrap justify-center" v-html="content?.kontaktlinks" />
        <div class="adress">
          <p class="text-white flex" v-html="content?.adresse" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    footer() {
      return this.$store.state.router.layout.footer || {};
    },
    content() {
      return this.$store.state.router.layout.content;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

.Footer {
  .contact-bar {
    padding: 10px 0;

    @media (max-width: theme("screens.md")) {
      display: none;
    }

    .impressum-kontakt, .some-links, .adress {
      padding: .5rem;
    }
    :deep(p) {
      margin: 0;
      color: var(--color-black);
      font-size: 1rem;
      &:not(:last-child) {
        @screen md {
          margin-right: 1rem;
        }
      }
      @screen lg {
        font-size: 1.25rem;
      }
    }
    :deep(a) {
      color: var(--color-black);
      font-size: 1rem;
      padding: 0.25rem 1rem;
      &:hover {
        color: var(--color-red);

      }
      @screen lg {
        font-size: 1.25rem;
      }
    }
  }

  .instagram-bar {
    padding: 2rem 0 3rem 0;

    @screen md {
      padding: 8rem 0 2rem 0;
    }

    :deep(.h1) {
      font-family: var(--font-primary);
    }
  }
}

</style>
