<template>

  <transition-group name="fade">
    <component
      :is="component.data.page"
      v-for="component of parts"
      :id="component.slug || component.data.slug"
      :key="component.id || component.data.id"
      :payload="component.data || component"
      class="fade-item"
    />
  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

const NotFound = defineAsyncComponent(() => import('@/components/structure/NotFound.vue'));
const Footer = defineAsyncComponent(() => import('@/components/structure/Footer.vue'));
const Impressum = defineAsyncComponent(() => import('@/components/pages/Impressum.vue'));

const Home = defineAsyncComponent(() => import('@/components/pages/Home.vue'));
const Photo = defineAsyncComponent(() => import('@/components/pages/Photo.vue'));
const FotografIn = defineAsyncComponent(() => import('@/components/pages/FotografIn.vue'));
const Haus = defineAsyncComponent(() => import('@/components/pages/Haus.vue'));
const HausDetail = defineAsyncComponent(() => import('@/components/pages/HausDetail.vue'));
const Film = defineAsyncComponent(() => import('@/components/pages/Film.vue'));
const FilmDetail = defineAsyncComponent(() => import('@/components/pages/FilmDetail.vue'));
const Atwork = defineAsyncComponent(() => import('@/components/pages/Atwork.vue'));
const WorkDetail = defineAsyncComponent(() => import('@/components/pages/WorkDetail.vue'));
const Roster = defineAsyncComponent(() => import('@/components/pages/Roster.vue'));
const Hallo = defineAsyncComponent(() => import('@/components/pages/Hallo.vue'));
const TextWall = defineAsyncComponent(() => import('@/components/parts/TextWall.vue'));
// const TextWall = defineAsyncComponent(() => import('@/components/parts/TextWall.vue'));

export default {
  components: {

    NotFound,
    Footer,
    Impressum,

    Home,
    Film,
    FilmDetail,
    Photo,
    FotografIn,
    Haus,
    HausDetail,
    Atwork,
    WorkDetail,
    Roster,
    Hallo,

  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
